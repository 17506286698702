import styled from 'styled-components';

const OrientationPopupStyle = styled.div`
	&.orPopup {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100vw;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: var(--sp6x) 0;
		background: var(--white);
		z-index: 100000;

		.orCont {
			max-width: var(--sp47x);
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			text-align: center;

			.orImage {
				width: 35%;
				margin-bottom: var(--sp2x);
			}

			.orLock {
				position: relative;
				width: 10%;
				padding-top: 10%;
				margin: var(--sp2x) 0 var(--sp1x);
				border: 1px solid var(--black);
				border-radius: 50%;

				svg {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate3d(-50%, -50%, 0);
					max-width: 45%;
				}
			}
		}
	}
`;

export default OrientationPopupStyle;
