import { memo as Memo, useMemo, useCallback, useEffect, useState } from 'react';
import { gsap, TweenLite, Power2 } from 'gsap';
import MorphSVGPlugin from 'gsap/dist/MorphSVGPlugin.js';

//* Helpers
import isMobile from '@helpers/isMobile';

//* Components
import Translate from '../Translate';

//* Styles
import CursorStyle from './style';

gsap.registerPlugin(MorphSVGPlugin);

var isScrolling;

const Cursor = Memo(() => {
	//! States
	const [state, setState] = useState({
		x: '-100%',
		y: '-100%',
		active: '',
		mixed: true,
		scroll: false,
	});

	//! Paths
	const circlePath = useMemo(() => 'M44.1,15.3c11.3,0,20.5,9.2,20.5,20.5s-9.2,20.5-20.5,20.5s-20.5-9.2-20.5-20.5S32.7,15.3,44.1,15.3z', []);
	const stickPath = useMemo(() => 'M80.6,121.3c-0.8,0-1.5-0.5-1.8-1.2l-31.9-69c-0.5-1,0-2.2,1-2.6c1-0.5,2.2,0,2.6,1l31.9,69c0.5,1,0,2.2-1,2.7c0,0,0,0,0,0C81.2,121.2,80.9,121.3,80.6,121.3z', []);
	const starPath = useMemo(() => 'M25.3,25L23.1,0L42,15.8l18.5-9.4l1.7,19.2l25.5,3.6L65.8,44.7L71,59.7l-21.7-3.9l-8.6,12.4l-7.1-14.9l-22.6,7.8l8.3-16.2L0.1,25.3L25.3,25z', []);
	const eyesPath = useMemo(() => 'M36.4,36.8L33,38.7l-2.5-3.1l3.4-1.9L36.4,36.8z M44.5,32.3l-3.4,1.9l-2.5-3.1l3.4-1.9L44.5,32.3z', []);
	const mouthPath = useMemo(() => 'M39,43.9c-2.6,0.3-5.2,0-7.7-0.7L29,40.3l0.3-0.2c5.1,1.6,10.6,1,15.3-1.6c0.9-0.5,1.8-1.1,2.6-1.8c2.9-2.3,4.7-5.7,4.9-9.3l0.3-0.2l2.4,2.9c-0.1,1.8-0.6,3.5-1.4,5c-0.9,1.7-2.2,3.2-3.7,4.4c-0.9,0.7-1.8,1.3-2.7,1.8C44.5,42.8,41.8,43.7,39,43.9z', []);

	//! Default State
	const defaultState = useCallback(() => {
		killAnimations();
		TweenLite.to('.cr-head path, .cr-circle, .cr-star, .cr-mr', 0.3, { scale: 0, opacity: 0 });
		TweenLite.to('.cr-stick', 0.3, { transformOrigin: 'top left', scale: 0, opacity: 0 });
		TweenLite.set('.cr-read', { opacity: 0 });
	}, []);

	//! Kill Animations
	const killAnimations = useCallback(() => {
		const animations = gsap.getTweensOf('.cr-head path, .cr-headG, .cr-circle, .cr-star, .cr-mr, .cr-stick, .cr-read');

		if (animations) {
			for (let animation of animations) {
				animation.kill();
			}
		}
	}, []);

	//! Setting Default State
	useEffect(() => {
		defaultState();
	}, []);

	//! Event Listeners
	useEffect(() => {
		document.addEventListener('mousemove', onMouseMove);
		document.addEventListener('mouseover', onMouseMove);
		document.addEventListener('mouseleave', onMouseLeave);
		window.addEventListener('scroll', scroll, false);

		return () => {
			document.removeEventListener('mousemove', onMouseMove);
			document.removeEventListener('mouseover', onMouseMove);
			document.removeEventListener('mouseout', onMouseLeave);
			window.removeEventListener('scroll', scroll, false);
		};
	}, []);

	const scroll = useCallback(() => {
		if (!state.scroll) {
			setState({ ...state, scroll: true });
			onMouseLeave();
		}

		!document.body.classList.contains('phone') && document.body.classList.add('phone');

		clearTimeout(isScrolling);

		isScrolling = setTimeout(function () {
			document.body.classList.remove('phone');
		}, 60);

		return () => {
			isScrolling && clearTimeout(isScrolling);
		};
	}, []);

	//! Mouse Move and Animation
	const onMouseMove = useCallback(
		(e) => {
			if (!isMobile()) {
				killAnimations();
				if (!state.scroll) {
					const el = e.target,
						x = e.clientX,
						y = e.clientY,
						winWH = window.innerWidth / 2,
						winHH = window.innerHeight / 2;

					let newState = { ...state },
						active = '',
						crContAnimTime = 0.1;

					if (checkActiveWithClass(el, 'crRead')) {
						gsap.killTweensOf('.cr-read');
						animate(0, starPath, 0, 0, 0, 0);

						active = 'crRead';
					} else if (checkActiveWithClass(el, 'crCircle')) {
						animate(1, circlePath, 0, 0, 0);
						crContAnimTime = 0.1;
						active = 'crCircle';
					} else if (checkActiveWithClass(el, 'crStick')) {
						gsap.killTweensOf('.cr-cont');
						animate(1, starPath, 0, 0, 1);

						crContAnimTime = 0.1;
						active = 'crStick';
					} else {
						const percentX = (x - winWH) / winWH;
						const percentY = (y - winHH) / winHH;
						const radian = (Math.atan(percentX, percentY) / Math.PI) * 360 + 45;
						animate(1, starPath, 1, radian, 0);

						active = 'crStar';
					}

					TweenLite.to('.cr-cont', crContAnimTime, { left: x, top: y, opacity: checkActiveWithClass(el, 'crHide') ? 0 : 1, ease: Power2.easeOut });

					const mixed = checkActiveWithClass(el, 'crLight') ? 'light' : checkActiveWithClass(el, 'crRead') ? 'mixedNone' : checkActiveWithClass(el, 'crDark') && 'dark';

					(active !== state.active || mixed !== state.mixed) && setState({ ...newState, active, mixed });
				}
			}
		},
		[state]
	);

	//! Checking element has animation class or not
	const checkActiveWithClass = useCallback((el, cl) => (el.classList.contains(cl) || !!el.closest(`.${cl}`)) && state.active !== cl, []);

	//! Mouse Leave
	const onMouseLeave = useCallback(() => {
		setState({ ...state, active: '' });
		defaultState();
	}, []);

	//! Animating svg paths
	const animate = useCallback((star, path, head, radian, stick, time = 0.2) => {
		TweenLite.to('.cr-star', 0.2, { morphSVG: path, opacity: star, scale: star });
		TweenLite.to('.cr-head path', 0.2, { opacity: head, scale: head }, 0);
		TweenLite.to('.cr-headG', 0.2, { svgOrigin: '40 37', rotate: radian }, 0);
		TweenLite.to('.cr-stick', 0.2, { transformOrigin: 'top left', scale: stick, opacity: stick }, 0.2);
		TweenLite.set('.cr-read', { opacity: time ? 0 : 1 }, 0);
	}, []);

	return (
		<CursorStyle className={`cr-cont cursor ${state.mixed ? [state.mixed] : 'mixed'}`}>
			<svg
				className={`cr-svg cursorSVG`}
				viewBox='0 0 87.7 121.3'>
				<path
					className={`cr-stick black`}
					d={stickPath}
				/>

				<g className={`cr-headG`}>
					<path
						className={`cr-star black`}
						d={starPath}
					/>
					<g className={`cr-head`}>
						<path
							className={`cr-eyes white`}
							d={eyesPath}
						/>
						<path
							className={`cr-mouth white`}
							d={mouthPath}
						/>
					</g>
				</g>
			</svg>

			<div className={`cr-read font-italic font-anonymous p-m crRead`}>
				<Translate val='clickToRead' />
			</div>
		</CursorStyle>
	);
});

export default Cursor;
