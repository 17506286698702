import { memo as Memo, useState, useCallback, useEffect } from 'react';

//* Components
import RotationAnimation from '../SvgComponents/RotationAnimation';

//* Styles
import MotionPopupStyle from './style';

const MotionPopup = Memo(() => {
	//! States
	const [active, setActive] = useState(false);

	const ClickRequestDeviceMotionEvent = useCallback(() => {
		if (window.DeviceOrientationEvent && typeof window.DeviceOrientationEvent.requestPermission === 'function') {
			window.DeviceOrientationEvent.requestPermission().then((response) => {
				setActive(false);

				if (response === 'granted') {
					localStorage.setItem('orp', 1);
				}
			});
		} else {
			setActive(false);
		}
	}, []);

	const CancelRequestDeviceMotionEvent = useCallback(() => {
		setActive(false);
	}, []);

	useEffect(() => {
		setActive(!localStorage.getItem('orp'));
	}, []);

	return active ? (
		<MotionPopupStyle className={'motionPopup'}>
			<div className={`motionPopupInner`}>
				<div className={'image'}>
					<RotationAnimation motion={true} />
				</div>

				<p className={`p-m font-poppins font-light`}>
					Our website contains features that require <span className='font-bold'>motion and orientation access</span> for full experience.
				</p>

				<div className={'actions'}>
					<div
						className={`font-poppins p-m`}
						onClick={CancelRequestDeviceMotionEvent}>
						Cancel
					</div>

					<div onClick={ClickRequestDeviceMotionEvent}>
						<svg
							width='102.745'
							height='49.717'
							viewBox='0 0 102.745 49.717'>
							<g
								id='Group_22182'
								transform='translate(-232.627 -500.564)'>
								<text
									id='Allow'
									transform='translate(282 532)'
									fontSize='20'
									className='font-anonymous'
									fontWeight='500'>
									<tspan
										x='-26.78'
										y='0'>
										Allow
									</tspan>
								</text>
								<g
									id='Path_10382'
									transform='matrix(0.998, -0.07, 0.07, 0.998, 232.627, 507.54)'>
									<path
										id='Path_15709'
										d='M89.741,17.985c7.619,0,14.11,1.185,17.665,4.346q3.81,3.471,2.709,9.312C108,42.613,91.729,50.872,73.015,55.794a157.449,157.449,0,0,1-33.222,4.971c-8.014.282-26.865,0-29.01-8.353-4.459-17.045,27.994-33.468,29.4-34.146L41.2,20.3c-.339.169-32.227,16.254-28.219,31.549,1.185,4.628,11.457,7.168,26.752,6.66,29.969-1.016,65.356-12.417,68.178-27.26.621-3.1-.056-5.475-1.975-7.224-4.8-4.4-17.5-4.967-36.572-1.806A280.728,280.728,0,0,0,41.26,28.6l-.621-2.145C42.163,26,69.762,17.985,89.741,17.985Z'
										transform='translate(-10.363 -17.985)'
									/>
								</g>
							</g>
						</svg>
					</div>
				</div>
			</div>
		</MotionPopupStyle>
	) : null;
});

export default MotionPopup;
