import { memo as Memo, useMemo } from 'react';
import NextImage from 'next/image';

//* HOC's
import withUIContext from '@context/consumerHOC/UIConsumer';

//* Components
import CustomLink from '@components/global/CustomLink';

const Logo = Memo(({ opened, lightHeader, onClick, darkMode, isMobile }) => {
    //! Logo color check
    const isLightLogo = useMemo(() => (lightHeader === 'reversed' && !darkMode) || (lightHeader && lightHeader !== 'reversed') || (darkMode && !lightHeader) || opened, [lightHeader, darkMode, opened]);

    //! Logo devise check
    const src = useMemo(() => `/images/headerLogoMenu/Logo${isMobile ? 'Mobile' : ''}${isLightLogo ? '' : 'Black'}.svg`, [isLightLogo, isMobile]);

    return (
        <CustomLink
            url='/'
            className='Logo'
            content={
                <NextImage
                    priority
                    src={src}
                    alt='logo'
                    width={200}
                    height={50}
                    onClick={onClick}
                    style={{ height: '100% !important', width: '200px' }}
                />
            }
        />
    );
});

export default withUIContext(Logo, ['darkMode', 'isMobile']);
