import { memo as Memo, useMemo, useEffect, useRef, useCallback } from 'react';
import { TweenMax, TimelineMax, TweenLite, Power3 } from 'gsap';
import { CSSTransition } from 'react-transition-group';

//* HOC's
import withUIContext from '@context/consumerHOC/UIConsumer';

//* Helpers
import isMobile from '@helpers/isMobile';

//* JSONs
import config from '@lib/config.json';

//* Components
import Menu from '@components/global/Menu';
import SocIcon from '@components/global/SocIcon';
import ContactInformation from './ContactInformation';
import DarkModeSwitcher from '@components/global/DarkModeSwitcher';
import LanguageSwitcher from '@components/global/LanguageSwitcher';
import { MenuSvg1, MenuSvg2, MenuSvg3, MenuSvg4 } from '../MenuSvg';

const MenuComponent = Memo((props) => {
	//! Refs
	let ref = useRef();
	let cardRef1 = useRef();
	let cardRef2 = useRef();
	let cardRef3 = useRef();
	let cardRef4 = useRef();
	const nodeRef = useRef(null);

	//! Routes from Config
	const routes = useMemo(() => config.routes.default, []);

	//! Big Menu Object
	const menuObj = useMemo(
		() => ({
			type: 'nav',
			contClass: 'menu-items-cont',
			itemClass: 'menu-item',
			items: [
				{
					name: routes['about-us'].name,
					path: routes['about-us'].path,
					svg: <MenuSvg1 ref={cardRef1} />,
				},
				{
					name: routes.contact.name,
					path: routes.contact.path,
					svg: <MenuSvg2 ref={cardRef2} />,
				},
				{
					name: routes.portfolio.name,
					path: routes.portfolio.path,
					svg: <MenuSvg3 ref={cardRef3} />,
				},
				{
					name: routes['services'].name,
					path: routes['services'].path,
					svg: <MenuSvg4 ref={cardRef4} />,
				},
			],
		}),
		[routes, MenuSvg1, MenuSvg2, MenuSvg3, MenuSvg4]
	);

	//! Blog and Awards Menu Object
	const menuObj2 = useMemo(
		() => ({
			type: 'nav',
			contClass: 'menu-items-cont2',
			itemClass: 'menu-item2',
			items: [
				{
					name: routes.team.name,
					path: routes.team.path,
				},
				{
					name: routes.awards.name,
					path: routes.awards.path,
				},
				{
					name: routes.blog.name,
					path: routes.blog.path,
				},
			],
		}),
		[routes]
	);

	//! Animation
	const anim = useMemo(() => new TimelineMax(), []);

	//! Default Positions
	const defaultPositions = useCallback(() => {
		TweenLite.set(cardRef1.current, { scale: 0, yPercent: -120, rotation: 60 }, 0);
		TweenLite.set(cardRef2.current, { scale: 0, yPercent: -100, rotation: -50 }, 0);
		TweenLite.set(cardRef3.current, { scale: 0, rotation: -20 }, 0);
		TweenLite.set(cardRef4.current, { scale: 0 }, 0);
	}, []);

	//! Hide Elements
	const hideElements = useCallback((k) => {
		TweenLite.killTweensOf([cardRef1.current, cardRef2.current, cardRef3.current, cardRef4.current]);
		k !== 1 && TweenLite.to(cardRef1.current.parentNode.firstChild, { opacity: 0 }, 0);
		k !== 2 && TweenLite.to(cardRef2.current.parentNode.firstChild, { opacity: 0 }, 0);

		k !== 1 && TweenLite.to(cardRef1.current, 0.5, { scale: 0, yPercent: -120, rotation: 60 }, 0);
		k !== 2 && TweenLite.to(cardRef2.current, 0.5, { scale: 0, yPercent: -100, rotation: -20 }, 0);
		k !== 3 && TweenLite.to(cardRef3.current, 0.3, { scale: 0, rotation: -30 }, 0);
		k !== 4 && TweenLite.to(cardRef4.current, 0.5, { scale: 0 }, 0);
	}, []);

	//! Start Animation
	const startAnimation = useCallback(() => {
		TweenLite.killTweensOf([cardRef1.current, cardRef2.current, cardRef3.current, cardRef4.current]);

		TweenLite.to(cardRef1.current.parentNode.firstChild, 0, { delay: 0.4, opacity: 1 }, 1);
		TweenLite.to(cardRef2.current.parentNode.firstChild, 0, { delay: 0.4, opacity: 1 }, 1);
		TweenLite.set('.navCont', { opacity: 1, delay: 0.2 });

		TweenLite.to(cardRef1.current, 1, { delay: 0.1, scale: 1, yPercent: 0, ease: Power3.easeOut, rotation: 0 }, 0);
		TweenLite.to(cardRef2.current, 1, { delay: 0.2, scale: 1, yPercent: 0, ease: Power3.easeOut, rotation: 0 }, 0);
		TweenLite.to(cardRef3.current, 1, { delay: 0.3, scale: 1, rotation: 0, ease: Power3.easeOut }, 0);
		TweenLite.to(cardRef4.current, 1, { delay: 0.4, scale: 1, ease: Power3.easeOut }, 0.7);
	}, []);

	useEffect(() => {
		if (props.opened) {
			defaultPositions();
			startAnimation();
			TweenMax.staggerTo(
				'.Linkmenu-item',
				1,
				{
					scale: 0.95,
				},
				0.2
			);
			TweenMax.staggerTo(
				'.language-socicon li, .contactInfoWrap *, .menu-items-cont2 *, .whiteIcon > a, .darkModeSwitcher',
				0,
				{
					opacity: 1,
					delay: 0.5,
				},
				0.1
			);
		} else {
			anim.clear();
		}
	}, [props.opened, props.winWidth]);

	const mouseOver = useCallback((e, k) => {
		if (!isMobile()) {
			TweenLite.set('.navCont', { opacity: 0 });
			TweenLite.killTweensOf(cardRef1?.current, cardRef2?.current, cardRef3?.current, cardRef4?.current);
			hideElements(k);
			const obj = e?.target?.getBoundingClientRect();
			const relX = e.pageX - obj.left;
			const el = eval(`cardRef${k}`)?.current;

			const newX = (relX - obj.width) / 15;
			TweenLite.to(el, 0.5, {
				scale: 1.1,
				yPercent: 0,
				rotation: 0,
				rotationX: ((relX - obj.width / 5) / obj.width) * 10,
				rotationZ: ((relX - obj.height / 20) / obj.height) * -1,
				x: newX,
			});
			TweenLite.to(el.parentNode.childNodes[0], 0.5, {
				x: newX * -1,
			});

			TweenLite.set(el.parentNode.childNodes[0], { opacity: 1 });
		}
	}, []);

	const mouseOut = useCallback((e, k) => {
		startAnimation();
	}, []);

	useEffect(() => {
		window.addEventListener('deviceorientation', animateItems, true);

		return window.removeEventListener('deviceorientation', animateItems);
	}, []);

	const animateItems = useCallback((e) => {
		const minMaxDeg = 30;
		const beta = e.beta > minMaxDeg ? minMaxDeg : e.beta < -minMaxDeg ? -minMaxDeg : e.beta;
		const gamma = e.gamma > minMaxDeg ? minMaxDeg : e.gamma < -minMaxDeg ? -minMaxDeg : e.gamma;
		const sizeBeta = (beta / minMaxDeg) * -5;
		const sizeGamma = (gamma / minMaxDeg) * 10;
		// TweenLite.set('.navCont', {
		// 	x: sizeGamma,
		// 	y: sizeBeta,
		// });

		// TweenLite.set('.menu-item .svg-max', {
		// 	x: -sizeGamma,
		// 	y: -sizeBeta,
		// });
	}, []);

	return (
		<CSSTransition
			unmountOnExit
			nodeRef={nodeRef}
			in={props.opened}
			classNames='custom-fade'
			timeout={{ enter: 400, exit: 700 }}>
			<div
				ref={nodeRef}
				className='menuContainer'>
				<div
					ref={ref}
					className='lgMenuWrap'>
					<Menu
						onClick={props.onClick}
						onMouseMove={mouseOver}
						onMouseOut={mouseOut}
						svgLayer={true}
						{...menuObj}
					/>
				</div>

				<div className='minMenuWrap'>
					{props.winWidth >= props.screenSizes.screenS ? (
						<>
							<div className='language-socicon'>{<LanguageSwitcher />}</div>

							<ContactInformation content={props.content} />

							<SocIcon
								type={2}
								whiteIcon={true}
								menuIcon={false}
								isSocial={true}
							/>

							<Menu
								onClick={props.onClick}
								{...menuObj2}
							/>

							<span className='darkModeSwitcher'>
								<DarkModeSwitcher />
							</span>
						</>
					) : (
						<>
							<Menu
								{...menuObj2}
								onClick={props.onClick}
							/>

							<ContactInformation content={props.content} />

							<div className='language-socicon'>
								<LanguageSwitcher />

								<SocIcon
									type={2}
									whiteIcon={true}
									menuIcon={false}
									isSocial={true}
								/>

								<span className='darkModeSwitcher'>
									<DarkModeSwitcher />
								</span>
							</div>
						</>
					)}
				</div>
			</div>
		</CSSTransition>
	);
});

export default withUIContext(MenuComponent, ['screenSizes', 'winWidth']);
