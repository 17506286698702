import { memo as Memo, useMemo } from 'react';
import { useParams, usePathname } from 'next/navigation';
import Link from 'next/link';

//* HOC's
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';


const LanguageSwitcher = Memo(({ selectedLang, languages }) => {
    const pathname = usePathname();

    const langs = useMemo(() => {
        return Object.keys(languages).map((lang) => {

            const route = pathname.replace(`/${selectedLang}`, '')


            return (
                <li
                    key={lang}
                    className='lengItem p-m font-semi-bold font-poppins'>
                    {selectedLang !== lang ? (
                        <>
                            <span className='hideText'>{languages[lang].params.name}</span>
                            <span className='showText'>
                                <Link
                                    className='crCircle'
                                    href={`/[locale]${route}`}
                                    as={`/${lang}${route}`}
                                >
                                    {languages[lang].params.name}
                                </Link>
                            </span>
                        </>
                    ) : (
                        <>
                            <span className='hideText'>{languages[lang].params.name}</span>
                            <span className='showText font-italic'>{languages[lang].params.name}</span>
                        </>
                    )}
                </li>
            );
        });
    }, [selectedLang, languages, pathname]);

    return <ul className='lengWrap'>{langs}</ul>;
});

export default withLanguageContext(LanguageSwitcher, ['selectedLang', 'languages']);
