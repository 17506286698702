'use client';

import { memo as Memo, useState, useEffect, Fragment } from 'react';

//* HOC's
import withUIContext from '@context/consumerHOC/UIConsumer';

//* Components
import Cursor from './global/Cursor';
import MotionPopup from './global/MotionPopup';
import OrientationPopup from './global/OrientationPopup';

const HighLevelComponent = Memo(({ highComponent, isApple, isMobile }) => {
	//! States
	const [touchDevice, setTouchDevice] = useState(true);

	useEffect(() => {
		setTouchDevice('ontouchstart' in window || navigator.maxTouchPoints);
	}, []);

	return (
		<Fragment>
			{highComponent}

			{isApple && !!touchDevice && <MotionPopup />}

			{!touchDevice && !isMobile && <Cursor />}

			{isMobile && <OrientationPopup />}
		</Fragment>
	);
});

export default withUIContext(HighLevelComponent, ['highComponent', 'isMobile', 'isApple']);
