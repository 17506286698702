import { memo as Memo } from 'react';

//* HOC's
import withUIContext from '@context/consumerHOC/UIConsumer';

const ContactInformation = Memo((props) => {
	return (
		<div className='contactInfoWrap'>
			{props.content.addresses.map((item, ind) => {
				return (
					<div
						key={ind}
						className='contactInfoItem'>
						<a
							href={`tel:${item.phone.replace(/\s+/g, '')}`}
							className='p-m font-poppins font-medium font-italic phone crCircle'
							target='_blank'
							rel='noopener noreferrer'>
							{item.phone}
						</a>

						{props.winWidth >= props.screenSizes.screenXS && (
							<a
								href={item.link}
								className='p-m font-poppins font-light font-italic location_address crCircle'
								target='_blank'
								rel='noopener noreferrer'>{`${item.address} ${item.location}`}</a>
						)}
					</div>
				);
			})}

			<a
				href={`mailto:${props.content.mail}`}
				className='p-m font-medium font-italic font-poppins mail crCircle'
				target='_blank'
				rel='noopener noreferrer'>
				{props.content.mail}
			</a>
		</div>
	);
});

export default withUIContext(ContactInformation, ['winWidth', 'screenSizes']);
