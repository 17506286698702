import { forwardRef } from 'react';

const MenuSvg1 = forwardRef((props, ref) => {
    return (
        <svg
            ref={ref}
            className={`svg-max`}
            preserveAspectRatio='none'
            viewBox='0 0 1044.0706 570.4404'>
            <path d='M1044.0706,570.44H0L245.8206,0h557.5' />
        </svg>
    );
});

const MenuSvg2 = forwardRef((props, ref) => {
    return (
        <svg
            ref={ref}
            className={`svg-max`}
            preserveAspectRatio='none'
            viewBox='-204 -229.2 817.4 601.6'>
            <path
                x='0px'
                y='0px'
                d='M217.5-229.2l395.9,333.1C380.6,380.6,14.6,455.7-204,271.7l0,0L217.5-229.2z'
            />
        </svg>
    );
});

const MenuSvg3 = forwardRef((props, ref) => {
    return (
        <svg
            ref={ref}
            className={`svg-max`}
            preserveAspectRatio='none'
            viewBox='0 0 921.553 728.663'>
            <path d='M342.5,172.591,423.523,0l78.089,181.671L630.273,52.984,658.159,209.4l154.58-62.74-38.3,155.253,112.573,54.363L770.29,427.517l19.257,143.6-148.66-67.893L623.142,660.451,499.679,552.887,410.7,683.177,322.568,540.97,170.323,636.348V462.525L0,452.236l141.67-102.85L45.757,179.893l155.395,54.877V68.414Z' />
        </svg>
    );
});

const MenuSvg4 = forwardRef((props, ref) => {
    return (
        <svg
            ref={ref}
            className={`svg-max`}
            preserveAspectRatio='none'
            viewBox='0 0 905 905'>
            <circle
                cx='452.5'
                cy='452.5'
                r='452.5'
            />
        </svg>
    );
});

MenuSvg1.displayName = 'MenuSvg1'
MenuSvg2.displayName = 'MenuSvg2'
MenuSvg3.displayName = 'MenuSvg3'
MenuSvg4.displayName = 'MenuSvg4'

export { MenuSvg1, MenuSvg2, MenuSvg3, MenuSvg4 };
