import { memo as Memo } from 'react';

//* Components
import CustomLink from '@components/global/CustomLink';

const Default = Memo((props) => {
	return (
		<CustomLink
			url={props.path}
			content={props.text}
		/>
	);
});

export default Default;
