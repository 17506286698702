import { memo as Memo } from 'react';
import { template } from '@/helpers/lodash';

//* HOC's
import withLanguageContext from '@context/consumerHOC/LanguageConsumer';

const Translate = Memo((props) => {
    let compiled = props.languages?.[props.selectedLang]?.[props.val] && template(props.languages[props.selectedLang][props.val]);

    return (compiled && compiled(props.vars)) || props.val || null;
});

export default withLanguageContext(Translate, '*');
