import { memo as Memo } from 'react';

const Custom = Memo((props) => {
	return (
		<a
			className={`crCircle ${props.path === '#' ? 'disabled' : ''}`}
			href={props.path}
			target={props.target || '_blank'}
			rel='noopener noreferrer'>
			{props.children || props.text}
		</a>
	);
});

export default Custom;
