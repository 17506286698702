import styled from 'styled-components';

const MotionPopupStyle = styled.div`
	&.motionPopup {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100vw;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: var(--sp3x);
		background: rgba(0, 0, 0, 0.5);
		z-index: 1000;

		.motionPopupInner {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			padding: var(--sp3x);
			max-width: var(--sp52x);
			background-color: var(--white);
			text-align: center;

			.image {
				width: 100%;
				max-width: 120px;
				margin-top: var(--sp2x);
				margin-bottom: var(--sp4x);
			}

			.actions {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: var(--sp6x);
			}
		}
	}
`;

export default MotionPopupStyle;
