import { memo as Memo, useRef, useEffect, useMemo } from 'react';
import { TweenLite, TimelineMax } from 'gsap';

//* HOC's
import withUIContext from '@context/consumerHOC/UIConsumer';

const DarkModeSwitcher = Memo((props) => {
	//! Refs
	const circleRef = useRef();
	const faceRef = useRef();
	const assRef = useRef();

	const tl = useMemo(() => new TimelineMax({ paused: true }), []);

	useEffect(() => {
		TweenLite.set(assRef.current, { x: '290%' });
		tl.to(circleRef.current, 0.5, { x: -115 }, 0).to(faceRef.current, 0.4, { x: -200 }, 0).to(assRef.current, 0.5, { x: 1 }, 0);
		return () => tl.kill();
	}, []);

	useEffect(() => {
		if (props.darkMode) {
			tl.play();
		} else {
			tl.reverse();
		}
	}, [props.darkMode, tl]);

	return (
		<svg
			className={`crCircle colorSwitcher`}
			viewBox='0 0 228.36 114.18'
			onClick={props.toggleDarkMode}>
			<rect
				className='svg-max'
				rx='57.09'
			/>
			<circle
				ref={circleRef}
				className={'circle'}
				cx='171.27'
				cy='57.09'
				r='47.09'
			/>
			<path
				ref={faceRef}
				className={'face'}
				d='M158.61,46.35l1.5,2.73-3.93,1.41,3.93,1.38-1.59,2.82L155.31,52l.69,4.17h-3.12l.66-4.17-3.24,2.73-1.65-2.85,3.93-1.41-3.87-1.38,1.53-2.73,3.3,2.64-.69-4.17H156L155.31,49Zm33.78,0,1.5,2.73L190,50.49l3.93,1.38-1.59,2.82L189.09,52l.69,4.17h-3.12l.66-4.17-3.24,2.73-1.65-2.85,3.93-1.41-3.87-1.38L184,46.35l3.3,2.64-.69-4.17h3.15L189.09,49ZM170.13,63.84a15.43,15.43,0,0,1,1.69.84q.84.45,1.32.66a2.45,2.45,0,0,0,1,.21q1.53,0,1.86-2h3.21a7.94,7.94,0,0,1-1.83,4.38,4.62,4.62,0,0,1-3.48,1.44,4.37,4.37,0,0,1-1.62-.29,14.93,14.93,0,0,1-1.74-.85,12.37,12.37,0,0,0-1.27-.66,2.65,2.65,0,0,0-1-.21q-1.53,0-1.89,2h-3.18A8.09,8.09,0,0,1,165,65a4.58,4.58,0,0,1,3.45-1.44A4.4,4.4,0,0,1,170.13,63.84Z'
			/>
			<path
				ref={assRef}
				className={'ass'}
				d='M54.9,82.4c-1.1,2.4-2.8,4.5-4.9,6.2h-3.4v-0.3c4.4-3.5,6.9-8.8,6.8-14.4c0.1-5.6-2.4-11-6.8-14.4v-0.3H50  c2.1,1.7,3.8,3.8,4.9,6.2C57.4,70.6,57.4,76.9,54.9,82.4L54.9,82.4z M59.2,65.2c1.1-2.4,2.8-4.5,4.9-6.2h3.4v0.4  c-4.4,3.5-6.9,8.8-6.8,14.4c-0.1,5.6,2.4,11,6.8,14.4v0.3h-3.4c-2.1-1.6-3.8-3.8-4.9-6.2C56.7,76.9,56.7,70.7,59.2,65.2L59.2,65.2z'
			/>
		</svg>
	);
});

export default withUIContext(DarkModeSwitcher, ['toggleDarkMode', 'darkMode']);
