import styled from 'styled-components';

const CursorStyle = styled.div`
	&.cursor {
		--lColor: var(--white);
		--dColor: var(--black);

		position: fixed;
		width: 60px;
		transform: translate3d(-30px, -30px, 0);
		z-index: 10000;
		pointer-events: none;

		.cursorSVG {
			overflow: visible;
		}

		&.mixed {
			mix-blend-mode: difference;
		}

		&.light {
			--dColor: var(--white);
			--lColor: var(--black);
		}

		.black {
			fill: var(--lColor);
		}

		.white {
			fill: var(--dColor);
		}

		.mixedNone {
			mix-blend-mode: none;
		}

		.crRead {
			position: absolute;
			top: 50%;
			left: 50%;
			padding: var(--sp3x);
			transform: translate3d(-50%, -50%, 0);
			color: var(--color2);
			border: 2px solid var(--color2);
			white-space: nowrap;
			text-transform: uppercase;
			border-radius: 50%;
			background: var(--color1);
			mix-blend-mode: none;
			pointer-events: none;
			backface-visibility: hidden;
		}
	}
`;

export default CursorStyle;
