import styled from 'styled-components';

const HeaderStyle = styled.header`
	&.header-container {
		--headerVertPadding: var(--sp2x);
		--LogoSize: 82px;

		pointer-events: none;

		.header-inner-cont {
			position: fixed;
			top: 0;
			left: 50%;
			transform: translate3d(-50%, 0, 0);
			width: 100%;
			padding-left: var(--headerVertPadding);
			padding-right: var(--headerVertPadding);
			margin: 0 auto;
			z-index: 5;
			mix-blend-mode: difference;
		}

		.Logo {
			width: var(--LogoSize);
			position: relative;
			z-index: 111;
			mix-blend-mode: difference;
		}

		.mixed {
			mix-blend-mode: difference;
		}

		& ~ .page {
			padding-top: var(--headerHeight);
		}

		.Header {
			--BurgerWidth: 100px;
			--BurgerHeight: 42px;
			--leftRightPadding: var(--containerPaddingLR);

			height: var(--headerHeight);
			padding-bottom: 20px;
			padding-top: 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;
			z-index: 111;

			> * {
				pointer-events: all;
			}

			.hamburger {
				position: relative;
				z-index: 11;
				width: var(--BurgerWidth);
				height: var(--BurgerHeight);
				color: var(--white);
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: var(--fontFamily2);
				font-weight: var(--ffMedium);
				font-size: var(--h6);

				.burgerLine {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;

					svg {
						transition: all var(--trTime) ease;
						transition-delay: 0.4s;

						path {
							stroke: var(--white);
							stroke-width: 4px;
							stroke-linecap: round;
							stroke-linejoin: round;
							stroke-dasharray: 600 600;
							stroke-dashoffset: 0;
						}
					}
				}

				&.white {
					color: var(--white);

					.burgerLine {
						svg {
							path {
								stroke: var(--white);
							}
						}
					}
				}

				&.reversed {
					color: var(--white);

					.burgerLine {
						svg {
							path {
								stroke: var(--white);
							}
						}
					}
				}
			}
		}

		.menuContainer {
			position: fixed;
			top: 0;
			left: 50%;
			width: 100vw;
			height: 100%;
			display: flex;
			justify-content: space-between;
			padding-top: var(--headerHeight);
			background-color: var(--color2);
			overflow-x: hidden;
			overflow-y: scroll;
			transform: translate3d(-50%, 0, 0);
			z-index: 4;
			min-height: 0;
			pointer-events: all;

			.lgMenuWrap {
				position: relative;

				.menu-item {
					display: flex;
					position: absolute;
					color: var(--color2);
					fill: var(--color1);
					font-weight: var(--ffMedium);
					font-family: var(--fontFamily2);

					.navCont {
						pointer-events: none;
						position: absolute;
						z-index: 11;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}

					.Navmenu-item {
						position: absolute;
						z-index: 11;

						.Linkmenu-item {
							pointer-events: all;
							transform: scale(3);
							display: inline-block;
							transform-origin: 50% 50%;
						}
					}

					svg {
						overflow: visible;
						transform-origin: 50% 50%;
						pointer-events: none;
					}

					&:nth-child(1) {
						.navCont {
							clip-path: polygon(12% 0, 85% 0, 110% 100%, -8% 100%);
						}
					}

					&:nth-child(2) {
						.navCont {
							clip-path: polygon(52% 0%, -15% 100%, 141% 100%);
						}
					}

					&:nth-child(4) {
						.navCont {
							clip-path: circle(62% at 54% 62%);
						}
					}

					&:nth-child(3) {
						.Linkmenu-item {
							font-weight: var(--ffLight);
							font-style: var(--ffItalic);
						}
					}
				}
			}

			.minMenuWrap {
				position: relative;
				height: 100%;
				padding-bottom: 20px;
				padding-right: var(--headerVertPadding);
				display: flex;
				flex-wrap: wrap;

				.darkModeSwitcher {
					position: absolute;
					z-index: 111;
					bottom: calc(100% + var(--sp3x));
					left: var(--headerVertPadding);

					svg {
						border: 2px solid var(--color1);
						border-radius: 100px;
						color: var(--color2);

						.svg-max {
							fill: var(--color2);
						}
					}
				}

				.lengItem,
				.contactInfoWrap *,
				.menu-items-cont2 *,
				.whiteIcon a,
				.darkModeSwitcher {
					opacity: 0;
					color: var(--color1);
				}

				.whiteIcon a > div {
					border-color: var(--color1);
				}

				.menu-items-cont2 {
					.menu-item2 {
						.Linkmenu-item2 {
							font-family: var(--fontFamily2);
							font-weight: var(--ffMedium);
							font-style: var(--ffItalic);
							font-size: var(--h6);
							text-transform: capitalize;
							text-decoration: underline;
						}
					}
				}

				.language-socicon {
					display: flex;
					justify-content: space-between;
				}

				.contactInfoWrap {
					position: relative;
					text-align: right;

					.contactInfoItem {
						.phone,
						.location_address {
							display: block;
						}
					}

					a {
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
			.menuContainer {
				.lgMenuWrap {
					padding-top: 95%;

					.menu-item {
						font-size: 36px;

						&:nth-child(1) {
							top: calc((var(--headerHeight) + 10px) * -1);
							left: -20%;
							width: 72.3%;

							.Navmenu-item {
								bottom: 12%;
								right: 14%;
							}
						}

						&:nth-child(2) {
							left: 40%;
							top: -5%;
							width: 66%;

							.Navmenu-item {
								top: 57%;
								left: 54%;
								transform: translate3d(-50%, -50%, 0);
							}
						}

						&:nth-child(3) {
							top: 17%;
							left: -18%;
							width: 62%;

							.Navmenu-item {
								top: 50%;
								left: 50%;
								transform: translate3d(-50%, -50%, 0);
							}
						}

						&:nth-child(4) {
							bottom: 0;
							right: 5%;
							width: 48.3%;

							.Navmenu-item {
								top: 50%;
								left: 50%;
								transform: translate3d(-50%, -50%, 0);
							}
						}
					}
				}

				.minMenuWrap {
					padding-top: 20px;

					.menu-items-cont2 {
						flex: auto;

						.menu-item2 {
							margin-top: var(--sp1x);

							&:first-child {
								margin-top: 0;
							}
						}
					}

					.contactInfoWrap {
						margin-bottom: var(--sp6x);
						flex: auto;

						.contactInfoItem {
							margin-bottom: var(--sp2-5x);

							.phone {
								margin-bottom: var(--sp1x);
							}
						}
					}

					.language-socicon {
						width: 100%;
						align-items: center;
					}
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			.menuContainer {
				.lgMenuWrap {
					padding-top: 75%;

					.menu-item {
						font-size: 88px;

						&:nth-child(1) {
							top: calc((var(--headerHeight) + 27px) * -1);
							left: -1%;
							width: 60%;

							.Navmenu-item {
								bottom: 12%;
								right: 0;
								left: 0;
								text-align: center;
							}
						}

						&:nth-child(2) {
							top: calc((var(--headerHeight) - 21px) * -1);
							left: 50%;
							width: 58.6%;

							.Navmenu-item {
								top: 52%;
								left: -5%;
								right: 0;
								text-align: center;
							}
						}

						&:nth-child(3) {
							top: 19%;
							left: -13%;
							width: 50%;

							.Navmenu-item {
								top: 50%;
								left: 56%;
								transform: translate3d(-50%, -50%, 0);
							}
						}

						&:nth-child(4) {
							bottom: -7%;
							left: 30%;
							width: 48.3%;

							.Navmenu-item {
								top: 50%;
								left: 50%;
								transform: translate3d(-50%, -50%, 0);
							}
						}
					}
				}

				.minMenuWrap {
					position: relative;

					.contactInfoWrap {
						display: flex;
						flex-direction: row-reverse;
						justify-content: space-between;
						order: 1;
						margin-bottom: var(--sp4x);
						flex: 1 1 100%;

						.contactInfoItem {
							flex: 1 1 50%;
						}

						.mail {
							margin-top: var(--sp4x);
						}
					}

					.language-socicon {
						display: flex;
						justify-content: flex-end;
						order: 2;
						flex: 1 1 100%;

						.lengWrap {
							position: absolute;
							top: 0;
							left: var(--headerVertPadding);
						}
					}

					.menu-items-cont2 {
						flex: 1 1 100%;
						order: 3;
					}
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
			.menuContainer {
				flex-direction: column;

				.minMenuWrap {
					padding-left: var(--headerVertPadding);
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			.menuContainer {
				.minMenuWrap {
					.contactInfoWrap {
						.contactInfoItem {
							max-width: var(--sp20x);

							.phone {
								margin-bottom: var(--sp2x);
							}
						}
					}

					.darkModeSwitcher {
						bottom: var(--sp2x);
						left: var(--headerVertPadding);
					}

					.menu-items-cont2 {
						margin-top: var(--sp5x);
						display: flex;
						justify-content: flex-end;
						align-content: flex-end;

						.menu-item2 {
							margin-left: var(--sp4x);

							&:first-child {
								width: 100%;
								float: right;
								display: flex;
								justify-content: flex-end;
								margin-bottom: var(--sp2x);
							}
						}
					}
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			.contactInfoWrap,
			.lengWrap {
				margin-top: var(--sp7-5x) !important;
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			.menuContainer {
				.lgMenuWrap {
					width: 72.8%;

					.menu-item {
						&:nth-child(4) {
							top: 60%;
						}
					}
				}

				.minMenuWrap {
					width: 27.2%;
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			.menuContainer {
				overflow-y: hidden;
				flex-wrap: wrap;

				.lgMenuWrap {
					padding-top: 54.2%;

					.menu-item {
						font-size: var(--h2);

						&:nth-child(1) {
							top: calc((var(--headerHeight) + 27px) * -1);
							left: -1%;
							width: 62%;

							.Navmenu-item {
								bottom: 12%;
								right: 0;
								left: 0;
								text-align: center;
							}
						}

						&:nth-child(2) {
							top: calc((var(--headerHeight) - 50px) * -1);
							left: 47%;
							width: 59.6%;

							.Navmenu-item {
								top: 52%;
								left: -5%;
								right: 0;
								text-align: center;
							}
						}

						&:nth-child(3) {
							top: 23%;
							left: -14%;
							width: 53%;

							.Navmenu-item {
								top: 49%;
								left: 57%;
								transform: translate3d(-50%, -50%, 0);
							}
						}

						&:nth-child(4) {
							right: 25px;
							width: 49.5%;

							.Navmenu-item {
								top: 22%;
								text-align: center;
								left: 50%;
								transform: translateX(-50%);
							}
						}
					}
				}

				.minMenuWrap {
					position: initial;
					justify-content: flex-end;

					.contactInfoWrap {
						display: flex;
						flex-wrap: wrap;
						justify-content: flex-end;

						.contactInfoItem {
							margin-bottom: var(--sp5x);
							max-width: var(--sp24x);
						}
					}

					.darkModeSwitcher {
						bottom: var(--sp3x);
					}

					.whiteIcon {
						margin-top: var(--sp4x);
					}

					.language-socicon {
						margin-bottom: var(--sp8x);
					}

					.menu-items-cont2 {
						margin-top: var(--sp8x);
						flex-wrap: wrap;
						align-items: flex-end;
					}
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			--headerVertPadding: var(--sp2-5x);

			.Header {
				--BurgerWidth: 125px;
				--BurgerHeight: 53px;
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			.Header {
				padding-top: var(--sp3x);
				padding-bottom: var(--sp3x);
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) {
			--headerVertPadding: var(--sp5x);

			.Header {
				--BurgerWidth: 130px;
				--BurgerHeight: 55px;
			}

			.menuContainer {
				.lgMenuWrap {
					width: 77.4%;
					padding-top: 49.2%;

					.menu-item {
						&:nth-child(1) {
							top: calc((var(--headerHeight) + 10px) * -1);
							left: -4.5%;
							width: 57%;
						}

						&:nth-child(2) {
							left: 45%;
							width: 55%;

							.Navmenu-item {
								top: 48%;
								left: -4%;
							}
						}

						&:nth-child(4) {
							right: 8%;

							.Navmenu-item {
								top: 14%;
							}
						}
					}
				}

				.minMenuWrap {
					width: 22.6%;
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
			.menuContainer {
				.lgMenuWrap {
					.menu-item {
						&:nth-child(4) {
							bottom: 15%;
							transform: translateY(46%);
						}
					}
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
			.menuContainer {
				.lgMenuWrap {
					.menu-item {
						&:nth-child(4) {
							bottom: 12%;
							transform: translateY(46%);
						}
					}
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
			--headerVertPadding: var(--sp5x);

			.Header {
				--BurgerWidth: 130px;
				--BurgerHeight: 55px;
			}

			.menuContainer {
				.lgMenuWrap {
					width: 79%;
					padding-top: 49.2%;

					.menu-item {
						&:nth-child(1) {
							top: calc((var(--headerHeight) + 42px) * -1);
							left: -2%;
							width: 50.7%;

							.Navmenu-item {
								bottom: 8%;
							}
						}

						&:nth-child(2) {
							top: calc((var(--headerHeight) + 42px) * -1);
							left: 45%;
							width: 51%;

							.Navmenu-item {
								top: 50%;
								left: -4%;
							}
						}

						&:nth-child(3) {
							top: 20%;
							left: -9%;
							width: 39%;
						}

						&:nth-child(4) {
							right: 8%;
							width: 45.5%;

							.Navmenu-item {
								top: 14%;
							}
						}
					}
				}

				.minMenuWrap {
					width: 21%;

					.language-socicon {
						margin-bottom: var(--sp4x);
					}

					.menu-items-cont2 {
						margin-top: var(--sp4x);

						> li {
							margin-bottom: var(--sp2x);
						}
					}

					.contactInfoWrap {
						.contactInfoItem {
							margin-bottom: var(--sp4x);
						}
					}
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			--headerVertPadding: var(--sp5x);

			.Header {
				--BurgerWidth: 135px;
				--BurgerHeight: 58px;
			}

			.menuContainer {
				.lgMenuWrap {
					width: 76%;
					padding-top: 49%;

					.menu-item {
						&:nth-child(1) {
							top: calc((var(--headerHeight) + 20px) * -1);
							width: 56%;

							.Navmenu-item {
								bottom: 8%;
							}
						}

						&:nth-child(2) {
							top: calc((var(--headerHeight)) * -1);
							left: 50%;
							width: 56%;

							.Navmenu-item {
								top: 47%;
								left: -1%;
							}
						}

						&:nth-child(3) {
							top: 29%;
							left: -14%;
							width: 49%;

							.Navmenu-item {
								top: 50%;
								left: 59%;
							}
						}

						&:nth-child(4) {
							right: 10%;
							width: 49.5%;

							.Navmenu-item {
								top: 14%;
							}
						}
					}
				}

				.minMenuWrap {
					width: 24%;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			--headerVertPadding: var(--sp8x);

			.Header {
				--BurgerWidth: 175px;
				--BurgerHeight: 75px;

				padding-top: 26px;
				padding-bottom: 38px;
			}

			.menuContainer {
				.lgMenuWrap {
					width: 78.5%;
					padding-top: 43.5%;

					.menu-item {
						&:nth-child(1) {
							top: calc((var(--headerHeight) + 125px) * -1);
							width: 57%;

							.Navmenu-item {
								bottom: 6%;
							}
						}

						&:nth-child(2) {
							top: calc((var(--headerHeight) + 56px) * -1);
							left: 50%;
							width: 54%;

							.Navmenu-item {
								top: 47%;
								left: -1%;
							}
						}

						&:nth-child(3) {
							top: 27%;
							left: -10%;
							width: 43.5%;

							.Navmenu-item {
								top: 51%;
								left: 56%;
							}
						}

						&:nth-child(4) {
							right: 10%;
							width: 49.5%;

							.Navmenu-item {
								top: 14%;
							}
						}
					}
				}

				.minMenuWrap {
					width: 21.5%;

					.contactInfoWrap {
						.contactInfoItem {
							max-width: var(--sp32x);
						}
					}
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
			--headerVertPadding: var(--sp12x);

			.Header {
				--BurgerWidth: 230px;
				--BurgerHeight: 100px;
				--leftRightPadding: var(--headerVertPadding);

				padding-top: 40px;
				padding-bottom: 60px;
			}

			.menuContainer {
				.lgMenuWrap {
					width: 78.5%;
					padding-top: 43.5%;

					.menu-item {
						&:nth-child(1) {
							top: calc((var(--headerHeight) + 90px) * -1);
							width: 51%;

							.Navmenu-item {
								bottom: 10%;
							}
						}

						&:nth-child(2) {
							top: calc((var(--headerHeight) + 65px) * -1);
							left: 45%;
							width: 52.5%;

							.Navmenu-item {
								top: 47%;
								left: -1%;
							}
						}

						&:nth-child(3) {
							top: 23%;
							left: -12%;
							width: 44%;

							.Navmenu-item {
								top: 48%;
								left: 57%;
							}
						}

						&:nth-child(4) {
							right: 15%;
							width: 49.5%;

							.Navmenu-item {
								top: 8%;
							}
						}
					}
				}

				.minMenuWrap {
					width: 21.5%;

					.whiteIcon {
						margin-top: var(--sp8x);
					}

					.contactInfoWrap {
						.contactInfoItem {
							max-width: var(--sp34x);
						}
					}
				}
			}
		}

		/* //! Logo sizes medias */
		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			--LogoSize: 200px;
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			--LogoSize: 225px;
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			--LogoSize: 245px;
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
			--LogoSize: 360px;
		}
	}
`;

export default HeaderStyle;
