import { memo as Memo, useCallback } from 'react';
import { usePathname } from 'next/navigation';

//* Components
import MenuItem from './menuItem';

const Menu = Memo((props) => {
	const pathname = usePathname();

	const getParentClassFromActiveChild = useCallback(
		(child) => {
			let el = child.filter((i) => i.path === pathname);
			return el.length ? 'active disabled' : '';
		},
		[pathname]
	);

	const menuItems = (arr, t, c = '') => {
		return arr.map((o, k) => {
			const parentClass = o.child ? getParentClassFromActiveChild(o.child.items) : '';

			return (
				<MenuItem
					key={k}
					svg={o.svg}
					item={o}
					classes={c + ' ' + parentClass}
					type={o.type ? o.type : t}
					path={o.path}
					navLinksClass={o.navLinksClass}
					indexClass={`index-${k}`}
					onMouseMove={(e) => props.onMouseMove(e, k + 1)}
					onMouseOver={(e) => props.onMouseMove(e, k + 1)}
					onMouseOut={(e) => props.onMouseOut(e, k + 1)}>
					{o.svg}

					{o.child && <ul className={props.childContClass}>{menuItems(o.child.items, o.child.type || o.type || t, o.className || props.childItemClass)}</ul>}
				</MenuItem>
			);
		});
	};

	return (
		<ul
			className={props.contClass || ''}
			onClick={props.onClick}>
			{menuItems(props.items, props.type, props.itemClass, props.navLinksClass)}
		</ul>
	);
});

export default Menu;
