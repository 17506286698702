import { memo as Memo } from 'react';

//* Components
import CustomLink from '@components/global/CustomLink';

const Nav = Memo((props) => {
	return (
		<CustomLink
			className={'Link' + props.className}
			url={props.path}
			content={props.children}
			nav={true}
		/>
	);
});

export default Nav;
