import { memo as Memo } from 'react';

//* Components
import CustomLottie from '../../CustomLottie';

//* JSONs
import motionJSON from './motionJSON.json';
import rotationJSON from './rotationJSON.json';

const RotationAnimation = Memo(({ motion }) => {
	return (
		<CustomLottie
			isStopped={false}
			animData={motion ? motionJSON : rotationJSON}
		/>
	);
});

export default RotationAnimation;
